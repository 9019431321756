import React from "react"
import Faq from "../components/Faq"
import SEO from "../components/SEO"

const faq = () => {
  return (
    <>
      <SEO
        title="Mumbai Escorts Faq’s | Ask your question here"
        description="Are you facing any issue in booking our call girl service Mumbai, or Do you want to ask any question regarding our Escort service, all your query will be solved here."
      />
      <Faq />
    </>
  )
}

export default faq
